
























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import SubManageDialog from './SubManageDialog.vue';
import { CurriculumManagementService } from '@/services/abilityAssessment/curriculum-management-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { SubManageDialog }
})
export default class ManageDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean = false) {
        return isRefresh;
    }
    @Inject(CurriculumManagementService)
    private curriculumManagementService!: CurriculumManagementService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        mainTypeName: '',
        mainTypeDesc: ''
    };
    public rules: any = {
        mainTypeName: [
            { required: true, message: '请输入测评主类型', trigger: 'blur' }
        ]
    };
    public labelPosition: String = 'right';
    public title: string = '';
    public tableData: any[] = [];
    public loading: Boolean = false;
    public multipleSelectionKey: any[] = [];
    public visible: boolean = true;
    // 子Dialog相关
    public subDialogVisible: boolean = false;
    public subDialogType: string = '';
    public subCurrentIndex: number = -1;
    public subCurrentRow: any = null;

    public created(): void {
        this.initData();
    }
    // 清空数据
    public clearData() {
        this.subDialogType = '';
        this.subCurrentRow = null;
        // this.subCurrentIndex = -1;
        // (this.$refs.multipleTable as any).clearSelection();
    }
    // 初始化数据
    public initData() {
        switch (this.dialogType) {
            case ADD:
                this.title = '新增测评主类型';
                break;
            case EDIT:
                this.title = '编辑测评主类型';
                // 初始化表单和table
                this.formInfo = deepCopy(this.defaultData);
                this.tableData = deepCopy(this.formInfo.children);
                break;
        }
    }
    // 多选
    public handleSelectionChange(val) {
        this.multipleSelectionKey = val.map(t => t.key);
    }
    // 新增/编辑/查看
    public async handManage(type: string, idx: number, row?: any) {
        if (type === ADD) {
            this.subCurrentIndex = -1;
            this.subCurrentRow = null;
        } else if (type === EDIT) {
            this.subCurrentIndex = idx;
            this.subCurrentRow = deepCopy(row);
        }
        this.subDialogType = type;
        this.subDialogVisible = true;
    }
    // 删除
    public async handDelete(row: any, idx: number): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // TODO check子类型是否关联了测评项
            /**
             * 新增子类型(无id)：可直接删除
             * 编辑子类型(有id)：调接口判断是否关联了测评项，未关联的子类型才允许删除
             * */
            // 执行前端逻辑删除
            this.tableData.splice(idx, 1);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 批量删除
    public async handBatchDelete(): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // TODO check子类型是否关联了测评项
            /**
             * 新增子类型(无id)：可直接删除
             * 编辑子类型(有id)：调接口判断是否关联了测评项，未关联的子类型才允许删除
             * */
            // 执行前端逻辑删除
            this.tableData = this.tableData.filter(
                t => !this.multipleSelectionKey.includes(t.key)
            );
            this.multipleSelectionKey = [];
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 保存子Dialog
    public subDialogSubmit(data: any) {
        this.subDialogVisible = false;
        if (this.subDialogType === ADD) {
            this.tableData.push({ ...data, key: new Date().getTime() });
        } else {
            this.$set(
                this.tableData[this.subCurrentIndex],
                'subTypeName',
                data.subTypeName
            );
            this.$set(
                this.tableData[this.subCurrentIndex],
                'subTypeDesc',
                data.subTypeDesc
            );
        }
        this.clearData();
    }
    // 关闭子Dialog
    public subDialogCancel() {
        this.subDialogVisible = false;
        this.clearData();
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            if (!this.tableData.length) {
                this.$message.error("至少要添加一条数据！");
                return
            }
            // TODO 等接口调试
            const params = {
                ...this.formInfo,
                children: this.tableData
            };
            console.log('params', params);
            if (this.dialogType === ADD) {
                // const res = await this.curriculumManagementService.articleAdd(this.formInfo);
                this.$message({
                    message: '添加成功',
                    type: 'success'
                });
            } else if (this.dialogType === EDIT) {
                // const res = await this.curriculumManagementService.articleUpdate(
                //     this.formInfo
                // );
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
            }
            this.cancel(true);
        });
    }
}
