






















import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import { CurriculumManagementService } from '@/services/abilityAssessment/curriculum-management-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: {}
})
export default class ImportDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean = false) {
        return isRefresh;
    }
    @Inject(CurriculumManagementService)
    private curriculumManagementService!: CurriculumManagementService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        filessrc: '',
    };
    public rules: any = {
        filessrc: [
            { required: true, message: '请上传', trigger: ['blur', 'change'] }]
    };
    public audioDuration: number = 0;
    public fileList: any[] = [];
    public actionUrl: any = '';
    public showList: any = false;
    public labelPosition: String = 'right';
    public title: string = '导入文件';
    public visible: boolean = true;

    public created(): void {
        this.initData();
    }
    // 初始化数据
    public initData() {
        this.formInfo.filessrc = '';
        this.fileList = [];
        this.actionUrl = document.location.origin + '/api/application/role/play/users/upload';
    }
    public beforeAvatarUpload(files: { type: string; size: number; name: string; }) {
        // 文件类型进行判断
        console.log('files', files)
        var fileType = files.name.substring(files.name.lastIndexOf(".") + 1);
        const typeOne = fileType === "xlsx";
        const isLt20M = files.size / 1024 / 1024 < 20;
        if (!typeOne) {
            this.$message.error("上传文件格式错误!");
            this.fileList = [];
            this.audioDuration = 0;
            this.formInfo.filessrc = ''
            return false;
        } else {

        }
        this.audioDuration = 0;
        return typeOne
    }
    // 点击上传
    public async uploadClick(formName: any): Promise<void> {
        const addRef = (this.$refs.formInfo as any);
        // return;
        addRef.validate(async (valid: any) => {
            if (valid) {
                var data_ = {
                    'file': this.formInfo.filessrc
                };
                // 本地文件上传
                if (this.formInfo.filessrc != '' && this.formInfo.filessrc != null && this.formInfo.filessrc != undefined) {
                    const res = await this.curriculumManagementService.uploadFile(data_);
                    this.formInfo.filessrc = '';
                    this.fileList = [];
                    this.handleRemove()
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.cancel();
                } else {
                    this.$message.error("请上传文件!");
                }

            }
        })

    }
    public async handleRemove() {
        this.fileList = [];
        this.formInfo.filessrc = '';

    }
    public async handlePreview(file: any) {
        console.log(file);
    }
    public async successUpload(response: any, file: any, fileList: any) {
        console.log('178', response)
        if (response.header.code == 200) {
            this.$message({
                message: '导入成功',
                type: 'success'
            });
            this.cancel(true);
            this.fileList = [];
            this.formInfo.filessrc = '';
        } else {
            this.$message(response.header.message);
            this.cancel();
            this.fileList = [];
            this.formInfo.filessrc = '';

        }
    }
    public async errorUpload(response: any, file: any, fileList: any) {
        console.log('error', response)
        if (response.header.code == 200) {
            this.cancel();
            this.fileList = [];
            this.formInfo.filessrc = '';
        } else {
            this.$message(response.header.message);

        }
    }
    // 去后缀名 验证是否正确
    public getfileName(name: any) {
        // var suffix = name.substring(name.lastIndexOf('.'));
        var suffix = name.substring(name.lastIndexOf('.') + 1);
        if (suffix == 'word' || suffix == 'excel' || suffix == 'ppt' || suffix == 'pdf' || suffix == 'doc' || suffix == 'pptx') {
            return true
        } else {
            return false
        }

    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 保存表单
    // public submitForm(): void {
    //     (this.$refs.formInfo as any).validate(async (valid: any) => {
    //         if (!valid) return;
    //         // TODO 等接口调试
    //         const params = {
    //         };
    //         console.log('params', params);
    //         this.cancel(true);
    //     });
    // }
}
