

















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import ImportDialog from './components/ImportDialog.vue';
import ManageDialog from './components/ManageDialog.vue';
import { CurriculumManagementService } from '@/services/abilityAssessment/curriculum-management-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ImportDialog, ManageDialog }
})
export default class typeManagement extends Vue {
  @Inject(CurriculumManagementService)
  private curriculumManagementService!: CurriculumManagementService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  // Dialog相关
  public dialogVisible: boolean = false;
  public importDialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created(): Promise<void> {
    await this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 关闭新建/编辑Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 关闭导入Dialog
  public importDialogCancel(isRefresh: boolean) {
    this.clearData();
    this.importDialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 导入
  public downloadClick() {
    this.importDialogVisible = true;
  }
  // 导出
  public async exportClick() {
    // delete this.form.current;
    // delete this.form.pages;
    // delete this.form.size;
    // delete this.form.total;
    if (!this.tableData.length) {
      this.$message({
        type: 'error',
        message: '当前无下载内容！'
      })
      return;
    }
    // TODO
    console.log("导出", this.form);
    this.loading = true;
    // const res = this.CurriculumManagementService.cqueryArticleList.mimanageExportReport({...this.form});
    this.loading = false;
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    // const res = await this.CurriculumManagementService.cqueryArticleList(this.form);
    // TODO 模拟假数据
    setTimeout(() => {
      const res = {
        records: [
          {
            id: 1,
            aaa: '测评单名称1',
            bbb: 2,
            maintenanceTime: '2024-10-10 09:10:10'
          },
          {
            id: 2,
            aaa: '测评单名称2',
            bbb: 2,
            maintenanceTime: '2024-10-15 19:17:03'
          }
        ],
        total: 2,
        size: 10,
        current: 1
      };
      this.loading = false;
      this.tableData = res.records;
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }, 500);
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    this.currentRow = type !== ADD ? deepCopy(row) : null;
    this.dialogVisible = true;
  }
  //删除
  public async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      // TODO 等接口调试
      // await this.CurriculumManagementService.cqueryArticleInfo(param);
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
